import React, { useRef } from 'react'
import { ContactContainer, ContactRow, Column1, Column2, TopLine, Heading, Subtitle, Img, BtnWrap, Label, Input, Textarea } from './ContactUsElements'
import emailjs from '@emailjs/browser'
import Icon1 from '../../images/contact.svg'
import { ContactButton } from '../ButtonElements'


const ContactUsSection = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); 
    if (form.current[0].value === "" || form.current[1].value === "" || form.current[2].value === "") {
      console.log("ERROR");
    }
    else {
      emailjs.sendForm('service_ov33ugb', 'template_pf4810x', form.current, 'MsiN8me6lMxTBGeH4')
        .then((result) => {
          console.log(result.text);
          console.log(form.message);
        }, (error) => {
          console.log(error.text);
        });
    }
  }



  return (

    <>
      <ContactContainer lightBg={true} id={'contactus'}>
        {/* <ContactWrapper> */}
        <form ref={form} onSubmit={sendEmail}>
          <ContactRow imgStart={true}>
            <Column1>
              <TopLine>Let us know your thoughts</TopLine>
              <Heading lightText={false}>Contact Us!</Heading>
              <Subtitle darkText={true}>Queries,Feedbacks and Suggestions are most appreciated </Subtitle>
              <Label>Name</Label>
              <Input type="text" name="user_name" />
              <Label>Email</Label>
              <Input type="email" name="user_email" />
              <Label>Message</Label>
              <Textarea name="message" />
              {/* <FormButton type="submit" >Submit</FormButton> */}
            </Column1>
            <Column2>
              <Img src={Icon1} alt='temp' />
            </Column2>
          </ContactRow>
          <BtnWrap>
            <ContactButton type="submit" smooth={true} duration={500} spy={true} exact="true" offset={-80} primary={1} dark={false} dark2={0} >Submit Feedback</ContactButton>
          </BtnWrap>
          {/* <FormButton type="submit" style={{float: 'right'}} >Submit</FormButton> */}
        </form>
        {/* </ContactWrapper> */}
      </ContactContainer>
    </>

  )
}

export default ContactUsSection
