
import React from 'react'
import { ServicesCard, ServicesContainer, ServicesWrapper, ServicesVideo, ServicesH1, ServicesH2, ServicesP } from './ServicesElements'

import Icon1 from '../../images/webapp.mp4'
import Icon2 from '../../images/mobileapp.mp4'
import Icon3 from '../../images/uiux.mp4'
import Icon4 from '../../images/aiml.mp4'
import Icon5 from '../../images/animation.mp4'
import Icon6 from '../../images/productdevelopment.mp4'
import Icon7 from '../../images/webapp.png'
import Icon8 from '../../images/mobileapp.png'
import Icon9 from '../../images/uiux.png'
import Icon10 from '../../images/aiml.png'
import Icon11 from '../../images/animation.png'
import Icon12 from '../../images/productdevelopment.png'

const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>

                <ServicesCard>
                    <ServicesVideo src={Icon1} poster={Icon7} muted loop type='/video/mp4' autoPlay />
                    <ServicesH2>Web Application</ServicesH2>
                    <ServicesP>Software Applications through a Browser Interface </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesVideo src={Icon2}  poster={Icon8} muted loop type='/video/mp4' autoPlay/>
                    <ServicesH2> Mobile App Application</ServicesH2>
                    <ServicesP>Custom Designed Software for Mobile Devices </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesVideo src={Icon3} poster={Icon9} muted loop type='/video/mp4' autoPlay />
                    <ServicesH2>UI/UX Design</ServicesH2>
                    <ServicesP>Attractive and Appealing User Interface and User Experience</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesVideo src={Icon4} poster={Icon10} muted loop type='/video/mp4' autoPlay />
                    <ServicesH2>ML and AI Services</ServicesH2>
                    <ServicesP>Self Learning Applications curated for Business Needs</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesVideo src={Icon5} poster={Icon11} muted loop type='/video/mp4' autoPlay />
                    <ServicesH2>Animations & 3-D Models</ServicesH2>
                    <ServicesP>Picturesque Movement and Realistic 3 Dimensional Graphics</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesVideo src={Icon6}  poster={Icon12} muted loop type='/video/mp4' autoPlay/>
                    <ServicesH2>Product Development</ServicesH2>
                    <ServicesP>Develop and Manufacture Customized Software Products</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services