import React from 'react'

import { MainSContainer, MainSH1, MainSWrapper, MainSRow1, MainSRow2, MainSRow3, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, Img, ImgWrap } from './MainSElements.js'
import Icon2 from '../../images/ai3d.png'
import Icon1 from '../../images/vr.png'
import Icon3 from '../../images/xr.png'
const MainS = () => {
  return (
    <MainSContainer id='xr'>
      <MainSH1>XR</MainSH1>

      <MainSWrapper>
        <MainSRow1 imgStart='true'>
          <Column1>
            <TextWrapper>
              <TopLine>VR</TopLine>
              <Heading darkText='true'>Virtual Reality</Heading>
              <Subtitle darkText='true'>Create a whole new three-dimensional, computer-generated environment which you can explore and interact with, sometimes completely different from the real world.</Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon1} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow1>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow2 imgStart='true'>
          <Column1>
            <TextWrapper>
              <TopLine>AR</TopLine>
              <Heading darkText='true'>Augmented Reality</Heading>
              <Subtitle darkText='true'>Transform the real world with an interactive experience where the objects around you are enhanced by the addition of sound, visual elements, or other sensory stimuli.</Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon2} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow2>
      </MainSWrapper>

      <MainSWrapper>
        <MainSRow3 imgStart='true'>
          <Column1>
            <TextWrapper>
              <TopLine>MR</TopLine>
              <Heading darkText='true'>Mixed Reality</Heading>
              <Subtitle darkText='true'>Combine the best of both worlds - AR and VR. Enhance your surroundings like with AR, but interact with them like they were real, just like VR.</Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={Icon3} alt='temp' />
            </ImgWrap>
          </Column2>
        </MainSRow3>
      </MainSWrapper>

    </MainSContainer >
  )
}

export default MainS
