import React from 'react'
// import { Button } from '../ButtonElements'
import { BlogContainer, BlogWrapper, BlogRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Img, ImgWrap, BlogButton } from './BlogElements'
import Icon1 from '../../images/blog.svg'
import { useNavigate } from "react-router-dom"  

const Blog = () => {

  let navigate = useNavigate();

  const navigateToBlogPost = () => {
    navigate('/BlogPost')
  }

  return (
    <>
      <BlogContainer lightBg='true' id='blog'>
        <BlogWrapper>
          <BlogRow imgStart='true'>
            <Column1>
              <TextWrapper>
                <TopLine>Blog-Spot</TopLine>
                <Heading darkText='true'>Blog-It</Heading>
                <Subtitle darkText='true'>This is where we post our takes on new emerging technologies</Subtitle>
                <BtnWrap>
                  <BlogButton onClick={navigateToBlogPost} smooth={true} duration={500} spy={true} exact="true" offset={-80} primary={1} dark={false} dark2={0}>Take me there!</BlogButton>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Icon1} alt='temp' />
              </ImgWrap>
            </Column2>
          </BlogRow>
        </BlogWrapper>
      </BlogContainer>
    </>
  )
}

export default Blog
