import React from 'react'
import BlogPage from './BlogPage';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";

const BlogPost = () => {


    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <BlogPage />
            </ThemeProvider>
        </React.StrictMode>
    )
}
export default BlogPost