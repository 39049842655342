import styled from 'styled-components'

export const ServicesContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    

    @media screen and (max-width: 768px){
        height: 100%;
        padding-top:100px;
    }

    @media screen and (max-width: 480px){
        
        height: 100%;
    }
`


export const HeadingWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-row: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 10 50px;

    @media screen and (max-width: 1000px){
        grid-tempalte-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
    }`

export const ServicesWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 100px;
    padding: 50 50;  

    @media screen and (max-width: 1000px){
        grid-tempalte-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr;
        padding: 0 20px;
        grid-gap: 16px;
    }
`

export const ServicesCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 100%;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    

    // &:hover{
    //     // transform: scale(1.02);
    //     transition: all 0.2s ease-in-out;
    //     cursor: pointer;
    // }
`

export const ServicesVideo = styled.video`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    color: #0b0b0b;
    margin-bottom: 64px;

    @media screen and (max-width: 480px){
        font-size: 2rem;
    }
`

export const ServicesH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const ServicesP = styled.p`
    font-size: 1rem;
    text-align: center;
`