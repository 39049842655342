import React from 'react'

import { AboutContainer, AboutWrapper, AboutRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, Img, ImgWrap } from './AboutElements'
import Icon1 from '../../images/aboutus.svg'

const About = () => {
  return (
    <>
      <AboutContainer lightBg='true' id='about'>
        <AboutWrapper>
          <AboutRow imgStart='true'>
            <Column1>
              <TextWrapper>
                <TopLine>A bit about us</TopLine>
                <Heading darkText='true'>Our Vision</Heading>
                <Subtitle darkText='true'>XRIT is a Cross Reality (XR) based startup company founded in 2021.</Subtitle>
                <Subtitle darkText='true'>  We prioritize collaborative engagement with our clients in order to consequently provide to them custom-developed, unique and pertinent solutions.</Subtitle>
                {/* <BtnWrap>
                  <Button to="home" smooth={true} duration={500} spy={true} exact="true" offset={-80} primary={1} dark={false} dark2={0}>Get Started</Button>
                </BtnWrap> */}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Icon1} alt='temp' />
              </ImgWrap>
            </Column2>
          </AboutRow>
        </AboutWrapper>
      </AboutContainer>
    </>
  )
}

export default About
