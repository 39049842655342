import React, { useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Services from '../components/Services'
import Footer from '../components/Footer'
import ContactUsSection from '../components/ContactUsSection'
import About from '../components/About'
import Blog from '../components/Blog'
import { Body } from './PageElements.js';
import MainS from '../components/MainService'



const Home = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Body>
        <HeroSection />
        <About />
        <MainS />
        <Services />
        <Blog />
        <ContactUsSection />
      </Body>
      <Footer />
    </>
  )
}

export default Home