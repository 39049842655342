import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Pages'
import BlogPost from './components/BlogPost'

import { initializeApp } from "firebase/app";



const firebaseConfig = {
  apiKey: "AIzaSyD_wLklPRrCMqU1ws2pvaMLqfAmleY_wkA",
  authDomain: "xrit-ca.firebaseapp.com",
  projectId: "xrit-ca",
  storageBucket: "xrit-ca.appspot.com",
  messagingSenderId: "516358080759",
  appId: "1:516358080759:web:005c292eef0cbde26e6bf2"
};


initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/BlogPost" element={<BlogPost/>} />
      </Routes>
    </Router>
  );
}

export default App;
